import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const SlidesTextWrap = styled.div`
  padding: 60px 0 0;
    ${mediaMax.tablet`
      padding: 10% 0 0;
    `}
`;

export const SlidesTextContent = styled.div`
  max-width: 68%;
  margin: 0 auto;
  padding: 2vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${(props: { isReversed: boolean }) =>
    props.isReversed ? "row-reverse" : "row"};
  ${mediaMax.desktopSmall`
    max-width: 88%;
    align-items: flex-start;
  `}
  ${mediaMax.tablet`
    max-width: 100%;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
  `}
`;

export const SliderWrap = styled.div`
  height: 34vw;
  width: 50%;
  opacity: 0;
  display: flex;
  justify-content: ${(props: { isReversed: boolean }) =>
    props.isReversed ? "flex-start" : "flex-end"};
  ${mediaMax.desktopSmall`
    height: 100%;
    justify-content: ${(props: { isReversed: boolean }) =>
          props.isReversed ? "flex-end" : "flex-start"};
  `}
  ${mediaMax.tablet`
    width: 100%;
    padding: 0 10%;
    margin-bottom: 10%;    
  `}

  img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
  .splide,
  .splide__track,
  .splide__slide {
    height: 100%;
    width: 34vw;
    ${mediaMax.desktopSmall`
      width: 100%;
    `}
  }

`;
export const Description = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${(props: { isReversed: boolean }) =>
    props.isReversed ? "0" : "4vw"};
  padding-right: ${(props: { isReversed: boolean }) =>
    props.isReversed ? "4vw" : "0"};
  ${mediaMax.tablet`
    width: 100%;
    align-items: center;
    margin-bottom: 10%;   
    padding: 0 10%; 
  `}
  h3 {
    padding-bottom: 10px;
    max-width: 380px;
    width: 100%;
    color: #99948C;
  }
  p {
    max-width: 380px;
  }
`;
