import * as React from "react";
import {seoData} from "../data/seo";
import {discoverData} from "../data/discover";
import {introData} from "../data/intro";
import {navData} from "../data/nav";
import {textData} from "../data/text";
import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import SlidesText from "../components/introduction/slides-text";
import ContentText from "../components/content-text";
import ContentDiscover from "../components/content-discover";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import NewComposition from "../components/composition/test";
import useWindowSize from "../helpers/UseWindowSize";
import leftFooter from "../assets/images/compos/beach_compos_footer_left.png";
import rightFooter from "../assets/images/compos/beach_compos_footer_right.png";
import leftFooterM from "../assets/images/compos/beach-compos_mob_0_left.png";
import rightFooterM from "../assets/images/compos/beach-compos_mob_0_right.png";
import {CompositionWrapper} from "../components/composition/test/styled.index";

const IndexPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <main>
      <Seo page={seoData.introduction}/>
      <IntroVideo page={introData.introduction}/>
      <CompositionWrapper>
        <ContentText
          bgClassName={"shapes-bg bg-full"}
          text={width > 568 ? textData.introduction1.text : textData.introduction1.mobText}
          heading={textData.introduction1.heading}
        />
        <NewComposition
          shape={"circle"}
          top={"0"}
          right={"92vw"}
          height={'77vh'}
          mobBreakpoint={"phoneXL"}
          mobTop={"-12vh"}
          mobRight={"91.5vw"}
          mobHeight={'77vh'}
        />
        <NewComposition
          shape={"triangle"}
          top={"70px"}
          bottom={"0"}
          right={"0"}
          mobBreakpoint={"phoneXL"}
          mobTop={"auto"}
          mobBottom={"0"}
          mobRight={"0"}
          mobHeight={'60vh'}
        />
      </CompositionWrapper>
      <SlidesText/>

      <div>
        <ContentDiscover
          img={discoverData.tower1}
          imgHR={discoverData.tower1hr}
          imgMob={discoverData.tower1Mob}
          labels={true}
        />
        <ContentDiscover
          img={discoverData.tower2}
          imgHR={discoverData.tower2hr}
          imgMob={discoverData.tower2Mob}
          labels={false}
        />
      </div>
      <CompositionWrapper>
        <section>
          <ContentNextPage
            nextTitle={navData.beachTower.next}
            nextLink={navData.beachTower.link}
            mobHeight={"420px"}
            textAlign={'center'}
            vertAlign={"center"}
          />
          {width < 600 && <NewComposition imgSrc={leftFooterM} bottom={width > 380 ? "-25px" : "-30px"}
                                          left={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
          {width < 600 && <NewComposition imgSrc={rightFooterM} bottom={width > 380 ? "-25px" : "-30px"}
                                          right={width > 380 ? "0" : "-10px"} height={width > 400 ? '100vw' : '90vw'}/>}
        </section>
        {width >= 1024 && <NewComposition imgSrc={leftFooter} bottom={"0"} left={width >= 1250 ? "0" : "-4%"}
                                          height={width > 1200 ? "88vh" : '80vh'}/>}
        {width >= 1024 &&
            <NewComposition imgSrc={rightFooter} bottom={"0"} right={"0"} height={width > 1200 ? "88vh" : '80vh'}/>}
        <Footer/>
      </CompositionWrapper>
    </main>
  );
};

export default IndexPage;
