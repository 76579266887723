import React, { FC, useEffect, useRef } from "react";
import SliderImg from "./slider";
import {slidesData} from "../../../data/slides";

import {
  SlidesTextWrap,
  SlidesTextContent,
  SliderWrap,
  Description,
} from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import useWindowSize from "../../../helpers/UseWindowSize";

const SlidesText: FC = () => {
  const ContentWrapRef = useRef(null);
  const {width} = useWindowSize();
  useEffect(() => {
    ScrollTrigger.create({
      trigger: '.slideContent-0',
      toggleActions: "play none none none",
      start: "top top+=75%",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          '.slide-img-0',
          2.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
        )
        .fromTo(
          '.slide-desc-0',
          .5,
          { y: 40, ease: 'Power1.easeOut' },
          { y: 0, ease: 'Power1.easeOut' },
          "-=2"
        )
        .fromTo(
          '.slide-desc-0',
          1.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
          "-=2"
        )
    });
    ScrollTrigger.create({
      trigger: '.slideContent-1',
      toggleActions: "play none none none",
      start: "top bottom-=25%",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          '.slide-img-1',
          2.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
        )
        .fromTo(
          '.slide-desc-1',
          .5,
          { y: 40, ease: 'Power1.easeOut' },
          { y: 0, ease: 'Power1.easeOut' },
          "-=2"
        )
        .fromTo(
          '.slide-desc-1',
          1.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
          "-=2"
        )
    });
    ScrollTrigger.create({
      trigger: '.slideContent-2',
      toggleActions: "play none none none",
      start: "top bottom-=25%",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          '.slide-img-2',
          2.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
        )
        .fromTo(
          '.slide-desc-2',
          .5,
          { y: 40, ease: 'Power1.easeOut' },
          { y: 0, ease: 'Power1.easeOut' },
          "-=2"
        )
        .fromTo(
          '.slide-desc-2',
          1.5,
          { opacity: 0, ease: 'Power1.easeInOut' },
          { opacity: 1, ease: 'Power1.easeInOut' },
          "-=2"
        )
    });
  }, []);

  return (
    <SlidesTextWrap>
      {slidesData.map((item, i) => (
        <SlidesTextContent isReversed={item.isReversed} key={item.desc.heading} ref={ContentWrapRef} className={`slideContent-${i}`} >
          <SliderWrap isReversed={item.isReversed} className={`slide-img-${i}`}>
            <SliderImg data={item.images} dir={item.direction} />
          </SliderWrap>
          <Description isReversed={item.isReversed} className={`slide-desc-${i}`}>
            <h3 className={"heading fs-l"}>{item.desc.heading}</h3>
            <p className={"reg-text"}>{width > 578 ? item.desc.text : item.desc.mobText || item.desc.text}</p>
          </Description>
        </SlidesTextContent>
      ))}
    </SlidesTextWrap>
  );
};

export default SlidesText;
