import React, { FC, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Image from "../../../../components/image";
import "@splidejs/splide/dist/css/splide.min.css";

interface ISlider {
  data: string[];
  dir: string;
  altText?: string;
}

const SliderImg: FC<ISlider> = ({ data, altText }) => {
  const splideRef = useRef(null);

  return (
    <Splide
      ref={splideRef}
      options={{
        rewind: true,
        arrows: false,
        pagination: false,
        autoplay: true,
        type: "fade",
        speed: 1500,
        interval: 4000,
        pauseOnHover: false,
        pauseOnFocus: false,
      }}
    >
      {data.map((item, i) => (
        <SplideSlide key={`id${i}`}>
          <Image
            imageName={item}
            altText={altText || "The Ritz-Carlton Residences"}
          />
        </SplideSlide>
      ))}
    </Splide>
  );
};
export default SliderImg;
