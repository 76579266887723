import React from "react";

export  const slidesData = [
  {
    images: ["img-plate-1_1.jpg", "img-plate-1_2.jpg", "img-plate-1_3.jpg", "img-plate-1_4.jpg"],
    direction: "rtl",
    isReversed: false,
    desc: {
      heading: "Legendary Living",
      text: (<>Meticulous, personalized service provided by exemplary staff adept at anticipating and fulfilling the expressed and inferred wishes and needs of residents, consistently exceeding expectations—this is what distinguishes The Ritz-Carlton experience globally and elevates The Ritz-Carlton Residences, Pompano Beach to a truly matchless&nbsp;offering.</>),
      isWide: false,
    },
  },
  {
    images: [
      "img-plate-2_1.jpg",
      "img-plate-2_2.jpg",
      "img-plate-2_3.jpg",
      "img-plate-2_4.jpg",
      "img-plate-2_5.jpg",
      "img-plate-2_6.jpg",
    ],
    direction: "ltr",
    isReversed: true,
    desc: {
      heading: "Masterful Design",
      text: (<>Since establishing his first multidisciplinary practice in Milan more than 35 years ago, Piero Lissoni has been at the forefront of top-echelon global contemporary design and architecture. Today, with studios on both sides of the Atlantic, he is peerless in experience, influence, and eminence. As much a fine artist as he is an architect and designer, Lissoni’s holistic, contemplative, rigorous approach has placed him in a class of his own and has earned him accolades and awards the world over as well as a sterling reputation for executing his craft at the highest level of sophistication and&nbsp;quality.</>),
      isWide: false,
    },
  },
  {
    images: [
      "img-plate-3_1.jpg",
      "img-plate-3_2.jpg",
      "img-plate-3_3.jpg",
      "img-plate-3_4.jpg",
      "img-plate-3_5.jpg",
    ],
    direction: "rtl",
    isReversed: false,
    desc: {
      heading: "Visionaries",
      text: (
        <>
          The exemplary team behind The Ritz-Carlton Residences, Pompano Beach—comprised of Fortune International Group, Oak Capital, Fairwinds, Lissoni & Partners, and Revuelta Architecture International—is one that is even greater than the sum of its parts. This wonderful synergy, along with a shared commitment to excellence, innovation, and uncompromising quality, is what will make dreams come true for owners of the magnificent condos in this incomparable&nbsp;collection.  <br /> <br />{" "}
          Fortune, Oak, and Revuelta are peerless when it comes to being South Florida-focused, specialized and accomplished. Their collective wisdom, experience in the region, track record of outperforming the market and of delivering superior quality time and again—when combined with living legend Piero Lissoni’s scrupulous, masterful design work and Ritz-Carlton’s matchless, legendary service—guarantee an offering without&nbsp;equal.
        </>
      ),
      mobText: (<>Fortune International Group, Oak Capital, and Fairwinds are peerless when it comes to being South Florida-focused, specialized and accomplished. Joined with Lissoni & Partners and Revuelta Architecture International they form a team greater than the sum of its parts, sharing a commitment to excellence, innovation, and uncompromising quality. Their collective track record of outperforming the market, delivering superior quality, time and again—when combined with Ritz-Carlton’s matchless, legendary service—guarantees an offering without&nbsp;equal.</>),
      isWide: true,
    },
  },
];
